import React from "react"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Shape from "../components/shape/shape"
import FormCta from "../components/formCta/formCta"
import Triangle from "../components/triangle/triangle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FeaturedCaseStudy from "../components/featuredCaseStudy/featuredCaseStudy"
import Carousel from "../components/carousel/carousel"


const slugify = require("slugify")

const CaseStudyPage = ({ data: { caseStudy }, location }) => {
  let random = Math.floor(Math.random() * 8)
  return (
    <>
      <SEO
        titleOverride={caseStudy.metaTags && caseStudy.metaTags.title ? caseStudy.metaTags.title : caseStudy.title}
        descriptionOverride={caseStudy.metaTags && caseStudy.metaTags.description ? caseStudy.metaTags.description : null}
        pathnameOverride={location.pathname}
        imageOverride={caseStudy.metaTags && caseStudy.metaTags.image ? caseStudy.metaTags.image.url : null}
      />

      <section className="pb-6 mb-10 overflow-hidden border-b border-grey md:border-0 md:mb-6 md:pb-16 lg:mb-0 lg:pb-32">
        <div className="container relative">
          <div className="w-full mx-auto md:w-10/12">
            <div className="flex flex-wrap items-center mb-12 md:-mx-10 lg:-mx-12 xl:-mx-20 md:mb-0">
              {caseStudy.featuredImage && (
                <div className="relative w-full mb-12 md:w-1/2 lg:w-5/12 md:px-10 lg:px-12 xl:px-20 md:mb-0">
                  <Shape 
                    static={true}
                    index={random}
                    randomColor={true}
                  />
                  <div className="absolute top-0 z-10 w-full md:right-0 md:w-10/12">
                    <Img fluid={caseStudy.featuredImage.fluid} className="w-full" />
                  </div>
                </div>
              )}
              <div className="relative z-10 w-full md:w-1/2 lg:w-7/12 md:px-10 lg:px-12 xl:px-20">
                
                {/* @TODO Abstract to component */}
                <ol className="block mb-0">
                  <li className="text-base text-grey-dark" itemScope itemType="http://data-vocabulary.org/Breadcrumb">
                    <Link to="/work/" itemProp="url" className="text-base underline transition duration-500 ease-in-out text-grey-dark hover:text-secondary-dark focus:text-secondary-dark">
                      <span itemProp="title">Work</span>
                    </Link> /
                  </li>
                </ol>

                <div className="mt-6 md:mt-10 text-primary"><FontAwesomeIcon className="inline-block mt-2 mr-2 text-lg" icon="map-marker" size="sm" />{ caseStudy.location }</div>
                <h1 className="text-3xl md:text-4xl lg:text-6xl">{ caseStudy.heroHeading }</h1>
                <div className="mb-6 content content--reset md:mb-10" dangerouslySetInnerHTML={{ __html: caseStudy.heroExcerpt }}></div>

                <div className="flex flex-wrap sm:-mx-2">
                  <div className="w-full mb-4 sm:w-auto sm:px-2 sm:mb-0">
                    <a href="#what-we-did" className="block w-full mb-0 btn btn--primary sm:mb-2">What We Did</a>
                  </div>
                  <div className="w-full sm:w-auto sm:px-2">
                    <Link to="/contact/" className="block w-full btn btn--outline">Contact Us</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-12 mb-8 overflow-hidden border-b md:mb-16 lg:mb-24 md:pb-20 lg:pb-24 border-grey">
        <div className="container">
          <div className="w-full mx-auto md:w-10/12">
            <div className="flex flex-wrap md:-mx-6">
              {caseStudy.stats.map(({ heading, text }, index) => {
                return(
                  <div className="w-full mb-6 text-center md:w-1/3 md:mb-0 md:px-6" key={index}>
                    <span className="flex items-center justify-center block w-40 h-40 mx-auto mb-6 text-5xl text-white rounded-full font-display bg-secondary-dark">{ heading }</span>
                    <span className="block text-xl xl:text-2xl lg:px-6 xl:px-8 2xl:px-10" dangerouslySetInnerHTML={{ __html: text }}></span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="mb-8 md:mb-24 lg:mb-32" id="what-we-did">
        <div className="container">
          <div className="flex flex-wrap">
            <aside className="order-2 w-full md:w-4/12 lg:w-3/12 xl:w-2/12 md:order-1">
              <div className="sticky top-0 pt-12 lg:pb-10">
                { caseStudy.servicesUsed.length > 0 && (
                  <div className="pb-8 mb-8 border-b border-grey">
                    <h4 className="mb-6 text-primary">Services Used</h4>
                    
                    <nav aria-labelledby="servicesmenulabel" className="block mb-8">
                      <span id="servicesmenulabel" className="sr-only">Services Used Menu</span>
                      <ul>
                        {caseStudy.servicesUsed.map(({ title, model, slug, treeParent }, index) => {
                          // Generate the top level slug from the models apiKey
                          let slugged = slugify(model.apiKey + 's',{
                            lower: true,
                          }).replace(/[_]/g, '-');

                          return (
                            <li key={index} className="block w-full mb-2">
                              <Link
                                // if it has a parent, pipe it into the link, if not leave it out 
                                to={treeParent ? `/${slugged}/${treeParent.slug}/${slug}` : `/${slugged}/${slug}`}
                                className="text-lg link hover:text-secondary focus:text-secondary"
                              ><span className="mr-2 text-secondary">▸</span> { title }</Link>
                            </li>
                          )
                        })}
                      </ul>
                    </nav>
                  </div>
                )}

                <div className="pb-8 mb-8 border-b border-grey">
                  <span className="block mb-6 text-xl text-secondary">Adtrak is a full-service design, marketing and brand agency based in Nottingham.</span>

                  <Link to="/contact/" className="block text-xl link font-display text-secondary hover:text-secondary-dark focus:text-secondary-dark">
                    Get in touch
                    <span className={`ml-2 inline-block text-primary`}><Triangle size={`small`} /></span>
                  </Link>
                </div>
              </div>
            </aside>
            
            <article className="order-1 w-full pt-12 md:w-8/12 lg:w-9/12 xl:w-10/12 md:pl-10 lg:pl-16 md:order-2">
              <div className="flex flex-wrap">
                <div className="w-full mb-8 xl:w-2/3 lg:pr-16 xl:pr-24 xl:pl-8 xl:mb-0">
                  <span className="block content" dangerouslySetInnerHTML={{ __html: caseStudy.content }}></span>
                </div>
                <div className="w-full xl:w-1/3">
                  <div className="sticky top-0 flex flex-wrap md:-mx-4 xl:mx-0 xl:pt-12">
                    {caseStudy.supportingImages.map(({ fluid }, index) => {
                      return (
                        <div className="w-full mb-8 md:w-1/2 xl:w-full md:px-4 xl:px-4 md:mb-0 xl:mb-8" key={index}>
                          <Img key={index} backgroundColor={`#3B5CC4`} fluid={fluid} />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </article>
          </div>

          { caseStudy.clientTestimonial && (
              <section className="mt-16 mb-12 overflow-hidden md:mb-16 lg:mb-32 md:mt-24 lg:mt-32">
                <div className="container">
                  <blockquote className="max-w-4xl mx-auto blockquote__testimonial">
                    <div className="mb-6 text-lg md:text-xl" dangerouslySetInnerHTML={{ __html: caseStudy.clientTestimonial }}></div>
                    
                    { caseStudy.clientTestimonial && caseStudy.clientName && (
                      <footer>— <span className="text-lg md:text-xl font-display">{ caseStudy.clientName }</span></footer>
                    )}
                  </blockquote>
                </div>
              </section>
            )}
        </div>
      </section>

      { caseStudy.gallery.length > 0 && (
        <section className="mb-6 overflow-hidden md:mb-16 lg:mb-24">
          <div className="container">
            <Carousel images={caseStudy.gallery} />
          </div>
        </section>
      )}

      { caseStudy.featuredCaseStudy && (
        <FeaturedCaseStudy
          title={`Related Project <strong>&middot;</strong> ${caseStudy.featuredCaseStudy.title}`}
          slug={caseStudy.featuredCaseStudy.slug}
          blurb={caseStudy.featuredCaseStudy.teaserBlurb}
          image={caseStudy.featuredCaseStudy.teaserIpadImage}
        />
      )}

      <FormCta />
    </>
  )
}

export default CaseStudyPage

export const query = graphql`
  query CaseStudyQuery($slug: String!) {
    caseStudy: datoCmsCaseStudy(slug: { eq: $slug }) {
      title
      location
      heroHeading
      heroExcerpt
      content
      clientTestimonial
      clientName
      stats {
        heading
        text
      }
      metaTags {
        title
        description
        twitterCard
        image {
          url
        }
      }
      featuredCaseStudy {
        title
        teaserBlurb
        teaserIpadImage {
          fluid(
            maxWidth: 900
            imgixParams: {h: "500", w: "900", fit: "crop", crop: "center"}) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
        slug
      }
      featuredImage {
        fluid(imgixParams: {h: "775", w: "700", fit: "crop", crop: "center"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      supportingImages {
        fluid(imgixParams: {h: "720", w: "720", fit: "crop", crop: "center"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      gallery {
        fluid(
          maxWidth: 1600
          imgixParams: {h: "900", w: "1600", fit: "crop", crop: "faces, edges"}) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      servicesUsed {
        ... on DatoCmsService {
          slug
          title
          treeParent {
            title
            slug
          }
          model {
            id
            apiKey
            name
          }
        }
        ... on DatoCmsCreativeService {
          slug
          title
          treeParent {
            title
            slug
          }
          model {
            id
            apiKey
            name
          }
        }
      }
    }
  }
`